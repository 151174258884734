import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import MainFrame from './pages/home';

import './App.css';
function App() {
  return (
    <Switch>
			{/* <Route path="/login" component={Login} /> */}
			<PrivateRoute path="/" component={MainFrame} />
		</Switch>
  );
}

export default App;
