
import jsEncrypt from 'encryptlong'
import CryptoJS from 'crypto-js/crypto-js'
import setting from '../../config/defaultSettings'
const sm4 = require('sm-crypto').sm4
//根据加密方式解密数据
export function DecryptString(str) {
    if (!str) {
        return false
    }
    let info = localStorage.getItem('PlatformInfo_' + setting.name)
    let platformInfo = AesDecrypt(info, setting.aesKey)
    let encryptType = platformInfo.encrypt || platformInfo.globalConfig.encrypt
    if (encryptType == 'AES') {
        let content = AesDecrypt(str)
        return JSON.parse(content)
    } else if (encryptType == 'AES_RSA') {
        let content = RasDecryptLong(str)
        return content
    }
}

// RAS加密
export function RasEncryptLong() {
    const encrypt = new jsEncrypt()
    encrypt.setPublicKey(getGetKeys('publicKey'))
}
// RAS解密
export function RasDecryptLong(str) {
    const encrypt = new jsEncrypt()
    encrypt.setPublicKey(getGetKeys('privateKey'))
    let rasContent = decodeURIComponent(encrypt.decryptLong(str))
    let content = JSON.parse(AesDecrypt(rasContent))
    return content
}

// AES加密
export function AesEncrypt(str, skey) {
    let key = skey ? CryptoJS.enc.Utf8.parse(skey) : getGetKeys('secretKey')
    let encrypt = CryptoJS.AES.encrypt(encodeURIComponent(str), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    let content = encrypt.toString()
    return content
}
// SM4解密
export function sm4Decrypt(str, key) {
    let content = sm4.decrypt(str, key)
    try {
        return JSON.parse(content)
    } catch {
        return content
    }
}
// AES解密
export function AesDecrypt(str, skey) {
    let key = skey ? CryptoJS.enc.Utf8.parse(skey) : getGetKeys('secretKey')
    let encrypt = CryptoJS.AES.decrypt(str, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    let content = decodeURIComponent(CryptoJS.enc.Utf8.stringify(encrypt))
    return skey ? JSON.parse(content) : content
}

// 获取密钥
function getGetKeys(type) {
    let info = localStorage.getItem('PlatformInfo_' + setting.name)
    let platformInfo = AesDecrypt(info, setting.aesKey)
    console.log(platformInfo)
    if (type != 'secretKey') {
        return platformInfo.globalConfig[type]
    } else {
        let aesKey = ''
        let clientSecret = platformInfo.globalConfig[type]
        if (clientSecret.length >= 16) {
            aesKey = clientSecret.slice(0, 16)
        } else {
            aesKey = clientSecret
            for (let i = 0; i < 16 - clientSecret.length; i++) {
                aesKey += 0
            }
        }
        return CryptoJS.enc.Utf8.parse(aesKey)
    }
}
