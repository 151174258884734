import React from 'react';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import routers from '../../router';

class ContentMain extends React.Component {
  render () {
    return (
      <div className="layout-container">
        <Switch>
          {routers.map(item => {
            return (
              <PrivateRoute
                key={item.path}
                exact
                path={item.path}
                component={item.component}
              />
            );
          })}
          <Redirect exact from="/" to="/index" />
        </Switch>
      </div>
    )
  }
}

export default withRouter(ContentMain);