import React, { useEffect, useRef, useState } from "react";

import * as echarts from "echarts";
import Title from "../Title";
import { queryWeb_apiScreen_showResourceGradeCountNoLogin } from '../../api/zypc'

import "./index.scss";

let myChart;

export default (props) => {
  const { region } = props;
  const echartsRef = useRef();
 
  useEffect(() => {
    myChart = echarts.init(echartsRef.current);
  }, [echartsRef]);
  useEffect(() => {
    getData();
  }, [region]);
  const getData = async () => {
    queryWeb_apiScreen_showResourceGradeCountNoLogin({ region: region }).then((res) => {
      myChart && myChart.setOption(getOption(res));
    });
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      setTimeout(() => {
        myChart.resize();
      }, 200);
    });
  }, []);
  const getOption = (data) => {
    let total = 0;
    if (data) {
      data.forEach((item) => {
        total += Number(item.count);
      });
    }

    const option = {
      title: [
        {
          text: "资源采集数",
          subtext: `{val|${total}}`,
          top: "41%",
          left: "49%",
          textAlign: "center",
          itemGap: 8,
          subtextStyle: {
            rich: {
              val: {
                fontSize: 16,
                color: "#26B3FF",
              },
            },
          },
          textStyle: {
            color: "#9CDDFF",
            fontSize: 16,
          },
        },
      ],
      color: ["#FF52A8", "#7BFF2C", "#00D8FF", "#FFC600", "#F03131", "#B94BFF"],
      tooltip: {
        trigger: "item",
        formatter: "{b} :  ({d}%)",
      },
      dataset: {
        source: data,
      },
      series: [
        {
          type: "pie",
          minAngle: 60, //最小角度
          startAngle: 300, //起始角度
          avoidLabelOverlap: true, // 是否启用防止标签重叠策略
          radius: [60, 80],
          center: ["50%", "50%"],
          label: {
            show: true,
            color: "inherit",
            formatter: (p) => {
              console.log(p);
              return `${p.value.name} - ${p.value.count} \n 占比${p.value.percent}`;
            },
          },
        },
      ],
    };

    return option;
  };
  return (
    <div className="r-box">
      <Title title="旅游资源分级统计" />
      <div className="content" ref={echartsRef}></div>
    </div>
  );
};
