import axios from "axios";
import setting from "../config/defaultSettings";

import env from "./env";
const request = axios.create({
  // API 请求的默认前缀
  baseURL: env.baseAuth, //线上
  timeout: 12000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;
    if (error.response.status === 403) {
      console.log("禁止访问");
    }
    if (error.response.status === 400) {
      console.log(data.message);
    }
    if (error.response.status === 500) {
      console.log(data.message);
    }
    if (error.response.status === 401) {
      console.log(data.message);
    }
    return Promise.reject(error.response.data);
  }
  return Promise.reject({
    error_code: 1000000,
    error_message: "UnKnown Error!",
    message: "未知错误！",
    status: -1,
  });
};

request.interceptors.response.use((response) => {
  if (response.data) return response.data;
  else return errorHandler({ response });
}, errorHandler);

/* 登录 */
export function userLogin(parameter) {
  return request({
    url: "/sso/oauth/token",
    method: "post",
    data: parameter,
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/sso/captcha",
    method: "GET",
  });
}

// 退出登录
export function casLoginOut() {
  return request({
    headers: { token: sessionStorage.getItem(setting.name + "_ACCESS_TOKEN") },
    url: "/sso/oauth/logout",
    method: "post",
  });
}
// 获取短信验证码
export function getMsgCode(parameter) {
  return request({
    url: "/sso/sendMessage",
    method: "GET",
    params: parameter,
  });
}
