import React, { useEffect, useRef } from "react";

import * as echarts from "echarts";
import Title from "../Title";
import { queryWeb_apiScreen_showNewDiscoveryGradeStaticNoLogin } from "../../api/zypc";
import "./index.scss";

let myChart;
const colors = [
  "#FF52A8",
  "#7BFF2C",
  "#00D8FF",
  "#FFC600",
  "#F03131",
  "#B94BFF",
];
export default (props) => {
  const { region } = props;
  const echartsRef = useRef();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setTimeout(() => {
        myChart.resize();
      }, 200);
    });
  }, []);

  useEffect(() => {
    myChart = echarts.init(echartsRef.current);
  }, [echartsRef]);

  useEffect(() => {
    getData();
  }, [region]);

  const getData = async () => {
    queryWeb_apiScreen_showNewDiscoveryGradeStaticNoLogin({ region: region }).then((res) => {
      res.data.forEach((item) => {
        if (item.per === null) {
          item.per = "0.00%";
        } else {
          item.per = "100%";
        }
      });
      myChart && myChart.setOption(getOption(res.data));
    });
  };

  const getOption = (list) => {
    const option = {
      // 鼠标移入提示框
      tooltip: {
        trigger: "axis",
        backgroundColor: "rgba(50, 50, 50, 0.7)", // 提示框背景色
        borderColor: "#05050F", // 提示框边框色
        textStyle: {
          color: "#fff", // 提示框文本颜色
        },
        axisPointer: {
          type: "none",
        },
        // formatter: function (params) {
        //   console.log(params);
        //   let text =
        //     params[0].data.name +
        //     "<br>新发现总数：" +
        //     params[0].data.value +
        //     "<br>资源总数：" +
        //     params[0].data.total +
        //     "<br>新发现占比：" +
        //     params[0].data.per;
        //   return text;
        // },
      },
      // 图列组件
      legend: {
        top: 0, // 图列项的位置
        itemGap: 50, // 图列项之间的间距
        icon: "react", // 图列项的形状
        itemWidth: 20, // 图列项的大小
        itemHeight: 10,
        textStyle: {
          color: "#fff",
        },
      },
      // 网格
      grid: {
        bottom: "10%",
        right: "0%",
        top: "15%",
      },
      // x轴
      xAxis: [
        {
          type: "category",
          axisTick: {
            show: false, // 不显示刻度线
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#121a53",
            },
          },
          axisLabel: {
            color: "#9CDDFF",
            interval: 0, // 强制显示所有刻度标签
          },
          data: list.map((item) => {
            return item.name;
          }),
        },
      ],
      // y轴
      yAxis: [
        {
          axisLabel: {
            color: "#9CDDFF",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#121a53",
            },
          },
          type: "value",
          min: 0,
        },
      ],
      series: [
        {
          name: "新发现",
          type: "bar",
          z: 10000,
          barWidth: 15,
          itemStyle: {
            color: "#FF52A8",
          },
          itemStyle: {
            normal: {
              color: (params) => {
                return colors[params.dataIndex];
              },
            },
          },
          data: list.map((item) => {
            return {
              name: item.name,
              value: item.newDiscovery,
              per: item.per,
              total: item.total,
              // 新发现占比: item.per ? item.per : "0.00%",
            };
          }),
        },
        {
          name: "资源总数",
          type: "bar",
          barGap: "-100%",
          barWidth: 15,
          itemStyle: {
            normal: {
              color: "#0000",
              borderColor: (params) => {
                return colors[params.dataIndex];
              },
              barBorderRadius: [2, 2, 0, 0],
              borderWidth: 2,
            },
          },
          data: list.map((item) => {
            return {
              name: item.name,
              value: item.total,
              per: item.per,
              newDiscovery: item.newDiscovery,
            };
          }),
        },
      ],
    };

    return option;
  };
  return (
    <div className="r-box">
      <Title title="旅游资源新发现分级统计" />
      <div className="content" ref={echartsRef}></div>
    </div>
  );
};
