import React, { useRef, useEffect, useState } from "react";
import * as echarts from "echarts";
import {
  queryWeb_apiScreen_showResourceCountNoLogin,
  queryWeb_apiScreen_showResourceListNoLogin,
  queryWeb_apiScreen_showGetAreaJson,
  queryWeb_apiScreen_showResourceCountByRegionNoLogin
} from "../../api/zypc";
import "./index.scss";
import CountUp from "react-countup";
import five from "../../assets/five_level.png";
import four from "../../assets/four_level.png";
import three from "../../assets/three_level.png";
import two from "../../assets/two_level.png";
import one from "../../assets/one_level.png";
import zero from "../../assets/zero_level.png";
import back from "../../assets/back.png";
let myChart = null;
let systemRegion = JSON.parse(localStorage.getItem("INFO"))?.region.slice(0, 6);
let regionLevel = [
  {
    region: systemRegion,
    name: JSON.parse(localStorage.getItem("INFO"))?.regionName,
  },
];
let dataCount = [];
export default (props) => {
  const { region, setRegion } = { ...props };
  const echartsRef = useRef();
  const [resourceTotal, setResTototal] = useState({});
  const [grade, setGrade] = useState("5,4,3,2,1,0");
  const [regionName, setRegionName] = useState(
    JSON.parse(localStorage.getItem("INFO"))?.regionName
  );
  const [toBack, setToBack] = useState(false);

  const getOptions = (datas) => {
    let mapOptions = {
      // 提示浮窗样式
      tooltip: {
        trigger: "item",
        backgroundColor: "rgba(0,0,0,0.5)",
        textStyle: { color: "#fff" },
      },
      geo: [
        {
          map: region,
          roam: false,
          top: 65,
          tooltip: {
            show: true,
          },
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            disabled: true,
            normal: {
              areaColor: "#00438F",
              borderWidth: 0,
              shadowBlur: 0,
            },
          },
          emphasis: {
            disabled: true,
          },
        },
        {
          map: region,
          roam: false,
          top: 50,
          tooltip: {
            show: false,
          },
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#4285EC",
              borderColor: "#03FEF9",
            },
            emphasis: {
              areaColor: "#03FEF9",
            },
          },
          tooltip: {
            trigger: "item",
            show: true,
            formatter: function (params) {
              if (params.componentType === "geo") {
                let resCount = 0;
                let culturalCount = 0;
                dataCount.forEach((item) => {
                  if (item.regionName == params.name) {
                    resCount = item.tourismCount;
                    culturalCount = item.culturalCount;
                  }
                });
                return `<div class='cityName'>${params.name}</div>
               <div>
               <span>旅游类资源总数：</span>
               <span>${resCount}</span>
               </div>
               <div>
               <span>文化类资源总数：</span>
               <span>${culturalCount}</span>
               </div>`;
              } else {
                return `
               <div class='cityName'>
                  <span>资源名称：</span>
                  <span>${params.value[2]}</span>
               </div>
               <div>
                <span>行政位置：</span>
                <span>${params.value[5]}</span>
               </div>
               <div>
                <span>东经：</span>
                <span>${params.value[0]}</span>
               </div>
               <div>
                <span>北纬：</span>
                <span>${params.value[1]}</span>
               </div>
               <div>
                <span>资源等级：</span>
                <span>${params.value[4]}</span>
               </div>`;
              }
            },
          },
        },
      ],
      series: [
        {
          type: "scatter",
          coordinateSystem: "geo",
          geoIndex: 1,
          data: datas,
          symbol: function (params) {
            if (params[3] === "5") {
              return "image://" + five;
            } else if (params[3] === "4") {
              return "image://" + four;
            } else if (params[3] === "3") {
              return "image://" + three;
            } else if (params[3] === "2") {
              return "image://" + two;
            } else if (params[3] === "1") {
              return "image://" + one;
            } else if (params[3] === "0") {
              return "image://" + zero;
            }
          }, // svg图标
          z: 2,
          symbolSize: [27, 35], // symbols图标大小
        },
      ],
    };
    return mapOptions;
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      setTimeout(() => {
        myChart.resize();
      }, 200);
    });
  }, []);
  useEffect(() => {
    getData();
    getResTotal();
    getResourceCount();
  }, [region, grade]);

  useEffect(() => {
    myChart = echarts.init(echartsRef.current);
  }, [echartsRef]);
  // useEffect(() => {
  // }, [region]);
  // const getProvince = async ()=>{
  //   try {
  //     const res = await get(`/web-api/sys-area/areaTree/${}`)
  //   }
  // }
  // /web-api/sys-area/areaTree/
  const getResourceCount = async () => {
    queryWeb_apiScreen_showResourceCountByRegionNoLogin({region: region}).then((res) => {
      dataCount = res;
      
    })
  };
  const drawMap = async (datas) => {
    queryWeb_apiScreen_showGetAreaJson({
      region: region,
    }).then((res) => {
      myChart.off("click");
      echarts.dispose(echartsRef);
      echarts.registerMap(region, res);
      myChart.setOption(getOptions(datas));
      myChart.on("click", (params) => {
        let clickRegionCode = res.features.filter(
          (areaJson) => areaJson.properties.name == params.name
        )[0]?.properties.adcode;
        if (clickRegionCode) {
          setRegion(clickRegionCode);
          setTimeout(() => {
            setRegionName(params.name);
          }, 200);
          let _index = regionLevel.findIndex(
            (item) => item.region == clickRegionCode
          );
          if (_index == -1) {
            regionLevel.push({
              region: clickRegionCode,
              name: params.name,
            });
            if (regionLevel.length > 1) {
              setToBack(true);
            } else {
              setToBack(false);
            }
          }
        }
      });
    });
  };

  const getData = async () => {
    let _datas = [];
    queryWeb_apiScreen_showResourceListNoLogin({
      region: region.toString().length < 12 ? region + "000000" : region,
      grades: grade,
    }).then((res) => {
      console.log(12312321, res);
      let a = "";
      res.map((item) => {
        if (item.grade == 1) {
          a = "一级";
        } else if (item.grade == 2) {
          a = "二级";
        } else if (item.grade == 3) {
          a = "三级";
        } else if (item.grade == 4) {
          a = "四级";
        } else if (item.grade == 5) {
          a = "五级";
        } else if (item.grade == 0) {
          a = "未定级";
        }
        _datas.push([
          item.longitude,
          item.latitude,
          item.name,
          item.grade,
          a,
          item.administrativeAddress,
        ]);
      });
      drawMap(_datas);
    });
  };

  const initMap = (e) => {
    e.stopPropagation();
    let _index = regionLevel.findIndex((item) => item.region == region);
    if (_index > 0) {
      //存在且不是当前用户所在的最高层级
      setRegionName(regionLevel[_index - 1].name);
      setRegion(regionLevel[_index - 1].region);
      regionLevel.splice(_index, 1);
      if (regionLevel.length > 1) {
        setToBack(true);
      } else {
        setToBack(false);
      }
    }
  };

  //资源总数
  const getResTotal = async () => {
    queryWeb_apiScreen_showResourceCountNoLogin({ region: region }).then((res) => {
      setResTototal(res);
    });
  };

  const chooseGrade = async (value) => {
    if (value == grade) {
      setGrade("");
    } else {
      setGrade(value);
    }
  };

  return (
    <div className="index-map">
      <div className="index-map-top">
        <div>
          <p>旅游资源总数</p>
          <div className="item">
            <CountUp
              start={0}
              end={resourceTotal.tourismCount ?? 0}
              duration={1}
            ></CountUp>
          </div>
          {/* <PlayNumber
            className="item"
            number={resourceTotal.tourismCount ?? 0}
          /> */}
          {/* <div className="item">{resourceTotal.tourismCount ?? 0}</div> */}
        </div>
        <div>
          <p>文化资源总数</p>
          <div className="item">
            <CountUp
              start={0}
              end={resourceTotal.culturalCount ?? 0}
              duration={1}
            ></CountUp>
          </div>
          {/* <div className="item">{resourceTotal.culturalCount ?? 0}</div> */}
          {/* <PlayNumber className='item' number={resourceTotal.culturalCount??0} /> */}
        </div>
      </div>
      <div className="echarts-wrap">
        <div className="echart-box" ref={echartsRef}></div>
        <div
          className="echart-back"
          style={{ display: toBack === false ? "none" : "block" }}
        >
          <img className="img_back" onClick={initMap} src={back}></img>
        </div>
        <div className="echart-region-name">当前地区：{regionName}</div>
        <div className="level-list">
          <div className="flex">
            <div
              className={grade == "5,4,3,2,1,0" ? "active" : "level"}
              onClick={() => chooseGrade("5,4,3,2,1,0")}
            >
              全部
            </div>
            {/* <div>
              <img src={five}></img>
            </div> */}
          </div>
          <div className="flex">
            <div
              className={grade == "5" ? "active" : "level"}
              onClick={() => chooseGrade(5)}
            >
              五级
            </div>
            <div>
              <img src={five}></img>
            </div>
          </div>
          <div className="flex">
            <div
              className={grade == "4" ? "active" : "level"}
              onClick={() => chooseGrade(4)}
            >
              四级
            </div>
            <div>
              <img src={four}></img>
            </div>
          </div>
          <div className="flex">
            <div
              className={grade == "3" ? "active" : "level"}
              onClick={() => chooseGrade(3)}
            >
              三级
            </div>
            <div>
              <img src={three}></img>
            </div>
          </div>
          <div className="flex">
            <div
              className={grade == "2" ? "active" : "level"}
              onClick={() => chooseGrade(2)}
            >
              二级
            </div>
            <div>
              <img src={two}></img>
            </div>
          </div>
          <div className="flex">
            <div
              className={grade == "1" ? "active" : "level"}
              onClick={() => chooseGrade(1)}
            >
              一级
            </div>
            <div>
              <img src={one}></img>
            </div>
          </div>
          <div className="flex">
            <div
              className={grade == "0" ? "active" : "level"}
              onClick={() => chooseGrade(0)}
            >
              未定级
            </div>
            <div>
              <img src={zero}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
