import request from '../util/request'

/* 获取系统设置信息 */
export function getWeb_apiSys_configInfo() {
    return request({
        url: '/web-api/sys-config/info',
        method: 'get',
    })
}
/* 文化资源分类统计 */
export function queryWeb_apiScreen_showCulturalStaticNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/culturalStaticNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* 获取地图json文件) */
export function queryWeb_apiScreen_showGetAreaJson(parameter) {
    return request({
        url: '/web-api/screen-show/getAreaJson',
        method: 'get',
        params: parameter,
    })
}
/* 旅游资源新发现分级统计 */
export function queryWeb_apiScreen_showNewDiscoveryGradeStaticNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/newDiscoveryGradeStaticNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* 旅游资源新发现分类统计 */
export function queryWeb_apiScreen_showNewDiscoveryStaticNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/newDiscoveryStaticNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* 按照所处区域统计资源总数 */
export function queryWeb_apiScreen_showResourceCountByRegionNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/resourceCountByRegionNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* 资源总数及各类型数量及占比 */
export function queryWeb_apiScreen_showResourceCountNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/resourceCountNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* 旅游资源分级统计 */
export function queryWeb_apiScreen_showResourceGradeCountNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/resourceGradeCountNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* 地图上的资源点(用于地图上描点显示资源) */
export function queryWeb_apiScreen_showResourceListNoLogin(parameter) {
    return request({
        url: '/web-api/screen-show/resourceListNoLogin',
        method: 'get',
        params: parameter,
    })
}
/* test */
export function queryWeb_apiScreen_showTest(parameter) {
    return request({
        url: '/web-api/screen-show/test',
        method: 'get',
        params: parameter,
    })
}