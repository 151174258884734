import React, { useEffect, useState } from "react";
import "./index.scss";
import title from "../../assets/title.png";
import { get } from "../../util/fetch";
import {casLoginOut} from '../../api/auth.js'
export default () => {
  const getInfo = async () => {
    const res = await get("/web-api/sys-config/info");
    localStorage.setItem("INFO",JSON.stringify(res.data.globalConfig))
  };
  useEffect(() => {
    getInfo();
  }, []);
  const getToday = () => {
    let _date = new Date();
    let _year = _date.getFullYear();
    let _month = _date.getMonth() + 1;
    let _day = _date.getDate();
    return `${_year}年${_month.toString().padStart(2, "0")}月${_day
      .toString()
      .padStart(2, "0")}`;
  };
  function loginOut(){
    casLoginOut().then((res) => {
      localStorage.clear();
    sessionStorage.clear();
    window.location.reload()
    }).catch((err) => {
      console.log(err)
    });
   
  }
  return (
    <div className="app-title">
      <div className="left common">
        {/* <span className='icon'></span> */}
        <a href="https://wlztw.tournx.com" className="text">
          首页
        </a>
      </div>
      <div className="center">
        <img src={title} style={{ width: 500 }} />
      </div>
      <div className="right common">
        <span className="icon"></span>
        <span className="text">截止{getToday()}统计结果</span>
        <span className="loginOut" onClick={loginOut}>退出登录</span>
      </div>
    </div>
  );
};
