import Index from '../pages/index';

const routers = [
	{
		path: '/index',
		component: Index
	},
	
]

export default routers