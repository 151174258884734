import React, { useEffect, useState } from "react";
import "./index.scss";
import { get } from "../../util/fetch";

import LeftTop from "../../Widget/LeftTop";
import LeftButtom from "../../Widget/LeftButtom";
import CenterMap from "../../Widget/CenterMap";
import CenterButtom from "../../Widget/CenterButtom";
import RightTop from "../../Widget/RightTop";
import RightCenter from "../../Widget/RightCenter";
import RightBottom from "../../Widget/RightBottom";

export default () => {
  const [region, setRegion] = useState(JSON.parse(localStorage.getItem("INFO")).region);
  return (
    <div className="real-monitor">
      <div className="left">
        <LeftTop region={region} />
        <div style={{ width: 38, height: 38 }} />
        <LeftButtom region={region} />
      </div>
      <div className="center">
        <div style={{ flex: 1 }}>
          <CenterMap region={region} setRegion={setRegion} />
        </div>
        <CenterButtom region={region} />
      </div>
      <div className="right">
        <RightTop region={region} />
        <div style={{ width: 28, height: 28 }} />
        <RightCenter region={region} />
        <div style={{ width: 28, height: 28 }} />
        {/* 旅游资源新发现分级统计 */}
        <RightBottom region={region} />
      </div>
    </div>
  );
};
