
import "./index.scss"

export default (props) => {
 
  return (
    <div >
      <div className='c-title'>
          <span className="icon"/>
          <span className="text">
            {props.title}
          </span>
      </div>
    </div>
  )
}