export default {
  name: 'resourceScreen',
  navMode: true, //只展开一个菜单还是多个
  fixedSider: true, // 是否固定侧边栏
  fixedContent: true, //是否固定内容区域 仅sidemenu模式下生效
  mode: 'Local', //Local 本地模式 Hip 集成模式
  enableTabRouter: true, //是否启用 tab选项卡缓存打开的页面
  sideWidth: 220,
  setting: false,
  headerHeight: 40,
  staticRoute: false,
  aesKey: 'uwTZwrCZYh7zKROK',
  specialStr: '~·`!！@#$￥%^&*=+[]{}【】、|\\;:；：\'"“‘,./<>《》?？，。',
  specialStatus: true,
  logoImage: '',
  //是否显示注册
  needRegister: {
    show: false,
    text: '新用户注册',
    align: 'center',
  },
  navTheme: 'dark', // theme for nav menu
  primaryColor: '#1890ff', // '#F5222D', // primary color of ant design
  layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
  contentWidth: 'Fluid', // layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky siderbar
  colorWeak: false,
  menu: {
    locale: true,
  },
  title: '文旅主体资源管理',
  pwa: false,
  iconfontUrl: '',
}
