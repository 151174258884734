import React, { useEffect, useState } from "react";
import Title from "../Title";
import { queryWeb_apiScreen_showCulturalStaticNoLogin } from "../../api/zypc";
import "./index.scss";
const colors = [
  "#F7597B",
  "#FC542A",
  "#E3A227",
  "#C6EC36",
  "#47DE91",
  "#0FC9F7",
  "#197AFF",
];
export default (props) => {
  const { region } = props;
  const [info, setInfo] = useState({});
  const [total, setTotal] = useState(0);
  useEffect(() => {
    getData();
  }, [region]);
  const getData = async () => {
    queryWeb_apiScreen_showCulturalStaticNoLogin({ region: region }).then((res) => {
      console.log(res)
      setInfo(res);
      let _d = res;
      let _total =
        _d.ancientBooks +
        _d.immovableArtifacts +
        _d.intangibleHeritage +
        _d.localDrama +
        _d.movableArtifacts +
        _d.museumCollection +
        _d.traditionalMusic;
      setTotal(_total);
    });
  };
  return (
    <div className="l-box">
      <Title title="文化资源分类统计" />
      <div className="content">
        <div className="item">
          <p className="desc">
            <span className="cate">古籍</span>
            <span className="num">{info.ancientBooks}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width:
                  total > 0 ? `${(info.ancientBooks / total) * 100}%` : "0",
                background: colors[0],
              }}
            ></div>
          </div>
        </div>
        <div className="item">
          <p className="desc">
            <span className="cate">美术馆藏品</span>
            <span className="num">{info.museumCollection}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width:
                  total > 0 ? `${(info.museumCollection / total) * 100}%` : "0",
                background: colors[1],
              }}
            ></div>
          </div>
        </div>
        <div className="item">
          <p className="desc">
            <span className="cate">地方戏曲剧种</span>
            <span className="num">{info.localDrama}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width: total > 0 ? `${(info.localDrama / total) * 100}%` : "0",
                background: colors[2],
              }}
            ></div>
          </div>
        </div>
        <div className="item">
          <p className="desc">
            <span className="cate">传统器乐乐种</span>
            <span className="num">{info.traditionalMusic}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width:
                  total > 0 ? `${(info.traditionalMusic / total) * 100}%` : "0",
                background: colors[3],
              }}
            ></div>
          </div>
        </div>
        <div className="item">
          <p className="desc">
            <span className="cate">非物质文化遗产</span>
            <span className="num">{info.intangibleHeritage}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width:
                  total > 0
                    ? `${(info.intangibleHeritage / total) * 100}%`
                    : "0",
                background: colors[4],
              }}
            ></div>
          </div>
        </div>
        <div className="item">
          <p className="desc">
            <span className="cate">可移动文物</span>
            <span className="num">{info.movableArtifacts}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width:
                  total > 0 ? `${(info.movableArtifacts / total) * 100}%` : "0",
                background: colors[5],
              }}
            ></div>
          </div>
        </div>
        <div className="item">
          <p className="desc">
            <span className="cate">不可移动文物</span>
            <span className="num">{info.immovableArtifacts}</span>
          </p>
          <div className="barbox">
            <div
              className="bar"
              style={{
                width:
                  total > 0
                    ? `${(info.immovableArtifacts / total) * 100}%`
                    : "0",
                background: colors[6],
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
