import React, { useEffect, useState } from "react";
import "./index.scss";
import { get } from "../../util/fetch";
import { queryWeb_apiScreen_showResourceCountNoLogin } from '../../api/zypc'
import CountUp from "react-countup";
export default (props) => {
  let stateList = [];
  const { region } = props;
  const [data, setData] = useState({});
  const [dataIndex, setDataIndex] = useState(0);
  const [dataObj, setDataObj] = useState([]);
  // const [dataArr, setDataArr] = useState([]);
  // const gradeList = {
  //   0:'未评级',
  // }
  useEffect(() => {
    getData();
  }, [region]);
  const getData =  () => {
    
    queryWeb_apiScreen_showResourceCountNoLogin({ region: region }).then((res) => {
      console.log(res)
      res.typeCount.forEach((item) => {
        item.percents = parseFloat(item.percent);
      });
      stateList = res.typeCount;
      // setDataArr(res.data);
      setData(res.typeCount);
    }).catch((err) => {
      console.log(err)
    });


  };
  const btnMouseEnterEvent = (e) => {
    let el = document.getElementsByClassName("detail")[0];
    if (e.target.offsetTop && e.target.offsetLeft) {
      el.style.top = e.target.offsetTop - 50 + "px";
      el.style.left = e.target.offsetLeft + "px";
    }
    if (e.target.getAttribute("datarefa")) {
      let a = data[e.target.getAttribute("datarefa")].gradeData;
      setDataObj(a);
      setDataIndex(e.target.getAttribute("datarefa"));
      // setTimeout(() => {
      el.style.display = "block";
      // }, 100);
    }
  };

  const btnMouseLeaveEvent = (e) => {
    let el = document.getElementsByClassName("detail")[0];
    if (e.target.className.indexOf("card") != -1) {
      el.style.display = "none";
    }
  };
  return (
    <div>
      <div
        className="cb-box"
        onMouseOver={btnMouseEnterEvent}
        onMouseLeave={btnMouseLeaveEvent}
      >
        <div className="item">
          <div className="card t1" datarefa={0}>
            {/* <PlayNumber className="num" number={data[0]?.count} />
          <PlayNumber className="precent" number={data[0]?.percent} /> */}
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[0]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[0]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="precent">{data[0]?.percent}</span> */}
            </div>
            <div className="desc">{data[0]?.typeName}</div>
          </div>
          <div className="card t2" datarefa={1}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[1]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[1]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[1]?.count}</span>
            <span className="precent">{data[1]?.percent}</span> */}
            </div>
            <div className="desc">{data[1]?.typeName}</div>
          </div>
          <div className="card t3" datarefa={2}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[2]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[2]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[2]?.count}</span>
            <span className="precent">{data[2]?.percent}</span> */}
            </div>
            <div className="desc">{data[2]?.typeName}</div>
          </div>
          <div className="card t4" datarefa={3}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[3]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[3]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[3]?.count}</span>
            <span className="precent">{data[3]?.percent}</span> */}
            </div>
            <div className="desc">{data[3]?.typeName}</div>
          </div>
        </div>
        <div className="item">
          <div className="card b1" datarefa={4}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[4]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[4]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[4]?.count}</span>
            <span className="precent">{data[4]?.percent}</span> */}
            </div>
            <div className="desc">{data[4]?.typeName}</div>
          </div>
          <div className="card b2" datarefa={5}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[5]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[5]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[5]?.count}</span>
            <span className="precent">{data[5]?.percent}</span> */}
            </div>
            <div className="desc">{data[5]?.typeName}</div>
          </div>
          <div className="card b3" datarefa={6}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[6]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[6]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[6]?.count}</span>
            <span className="precent">{data[6]?.percent}</span> */}
            </div>
            <div className="desc">{data[6]?.typeName}</div>
          </div>
          <div className="card b4" datarefa={7}>
            <div className="info">
              <CountUp
                className="num"
                start={0}
                end={data[7]?.count}
                duration={1}
              ></CountUp>
              <CountUp
                className="precent"
                start={0}
                end={data[7]?.percents}
                decimals="2"
                duration={1}
                suffix="%"
              ></CountUp>
              {/* <span className="num">{data[7]?.count}</span>
            <span className="precent">{data[7]?.percent}</span> */}
            </div>
            <div className="desc">{data[7]?.typeName}</div>
          </div>
        </div>
      </div>
      <div className="detail">
        <div className="barBox">
          {/* {dataIndex} */}
          {/* {data[dataIndex]} */}
          <div className="title">{data[dataIndex]?.typeName}：</div>
          <div className="dataIndex">{data[dataIndex]?.count}</div>
        </div>
        {dataObj.map((item) => (
          <div className="item" key={item.grade}>
            <div className="barBox">
              <div className="title">{item.gradeName} ：</div>
              <div className="count">{item.count}</div>
              <div>{item.per}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
