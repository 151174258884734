import axios from "axios";
import setting from "../config/defaultSettings";
import env from "../api/env";
import { DecryptString } from "../util/encryption/index";
import { notification } from 'antd';

const request = axios.create({
  // API 请求的默认前缀
  baseURL: env.baseURL, //线上
  timeout: 12000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;
    // 从 localstorage 获取 token
    const token = sessionStorage.getItem(setting.name + "_ACCESS_TOKEN");
    if (error.response.status === 403) {
      notification.error({
        message: "Forbidden",
        description: "暂无权限",
      });
    }
    if (error.response.status === 500) {
      notification.error({
        message: "提示信息",
        description: `${error.response.data.message}`,
      });
    }
    if (error.response.status === 400) {
      notification.error({
        message: "提示信息",
        description: `${error.response.data.message}`,
      });
    }
    if (
      error.response.status === 401 ||
      (error.response.data.code === 2007 && error.response.data.message == "身份认证token过期") || 
      (error.response.data.code === 2000 && error.response.data.message == "无效的身份认证")
    ) {
      if (token) {
        notification.error({
          message: "认证失败",
          description: "认证授权信息已过期，请重新登录！",
        });
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload()
        }, 1500);
      }
    }
  }
  return Promise.reject(error);
};

const deleteEmptyArray = (data) => {
  for (let key in data) {
    let v = data[key];
    if (v instanceof Array) {
      if (v.length === 0) {
        // console.log('------deleted------', key, v)
        delete data[key];
      } else data[key].map((m) => deleteEmptyArray(m));
    } else if (v instanceof Object) {
      deleteEmptyArray(data[key]);
    }
  }
  return data;
};

request.interceptors.request.use((config) => {
  const token = sessionStorage.getItem(setting.name + "_ACCESS_TOKEN");
  if (token) {
    // console.log(token);
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  let needDecrypt = true;
  if (
    response.config.url.startsWith("/system/open/") ||
    response.config.url.indexOf("/open/") != -1 || 
    response.config.url.indexOf("/web-api/sys-config/info") != -1
  ) {
    needDecrypt = false;
  }
  if (response.data.code === 1 || response.data.code === 0) {
    if (response.data.data) {
      return needDecrypt
        ? DecryptString(response.data.data)
        : response.data.data;
    }
  } else if (!response.data.code && response.status === 200) {
    return deleteEmptyArray(response.data);
  } else {
    return errorHandler({ response });
  }
}, errorHandler);


export default request

