import axios from "axios";
import env from "../api/env";
import { sm4Decrypt } from "../util/encryption/index";

const baseURL = env.baseURL;
axios.defaults.timeout = 60 * 1000;
axios.defaults.baseURL = baseURL;
function generateSM4Key() {
  // 创建一个长度为16字节的Uint8Array，用于存储密钥
  const key = new Uint8Array(16);
  // 使用crypto API生成安全的随机字节填充到key中
  window.crypto.getRandomValues(key);
  // 将Uint8Array转换为十六进制字符串，方便显示或传输
  const keyHexString = Array.from(key)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return keyHexString;
}
export const get = (url, params = {}) => {
  const queryUrl = baseURL + url;
  return new Promise((resolve, reject) => {
    axios({
      url: queryUrl,
      method: "get",
      params: params,
      headers: {
        "x-sign": generateSM4Key(),
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let content = sm4Decrypt(res.data.data, res.config.headers["x-sign"]);
          console.log(content)
          resolve({
            data: content,
          });
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
