import React, { useEffect, useRef, useState } from "react";

import * as echarts from "echarts";
import Title from "../Title";
import { queryWeb_apiScreen_showCulturalStaticNoLogin } from '../../api/zypc'
import "./index.scss";
import { arrayMax } from "highcharts";

let myChart;

export default (props) => {
  const { region } = props;
  // console.log(region);
  const echartsRef = useRef();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setTimeout(() => {
        myChart.resize();
      }, 200);
    });
  }, []);
  useEffect(() => {
    myChart = echarts.init(echartsRef.current);
  }, [echartsRef]);
  useEffect(() => {
    getData();
  }, [region]);
  const getData = async () => {
    // console.log(region)
      queryWeb_apiScreen_showCulturalStaticNoLogin({ region: region }).then((res) => {
        console.log('新接口',res)
      let obj = {
        name: "古籍",
        value: res.ancientBooks,
      };
      let obj1 = {
        name: "美术馆藏品",
        value: res.museumCollection,
      };
      let obj2 = {
        name: "地方戏曲剧种",
        value: res.localDrama,
      };
      let obj3 = {
        name: "传统器乐乐种",
        value: res.traditionalMusic,
      };
      let obj4 = {
        name: "非物质文化遗产",
        value: res.intangibleHeritage,
      };
      let obj5 = {
        name: "可移动文物",
        value: res.movableArtifacts,
      };
      let obj6 = {
        name: "不可移动文物",
        value: res .immovableArtifacts,
      };
      let arr = [];
      arr.push(obj, obj1, obj2, obj3, obj4, obj5, obj6);
      myChart && myChart.setOption(getOption(arr));
    })

    
  };

  const getOption = (data) => {
    const option = {
      title: [
        {
          //   text: "资源采集数",
          //   subtext: `{val|${total}}`,
          top: "41%",
          left: "49%",
          textAlign: "center",
          itemGap: 10,
          subtextStyle: {
            rich: {
              val: {
                fontSize: 16,
                color: "#26B3FF",
              },
            },
          },
          textStyle: {
            color: "#9CDDFF",
            fontSize: 16,
          },
        },
      ],
      color: [
        "#FF52A8",
        "#7BFF2C",
        "#00D8FF",
        "#FFC600",
        "#F03131",
        "#B94BFF",
        "#91cc75",
      ],
      tooltip: {
        trigger: "item",
        formatter: "{b} :  ({d}%)",
      },
      dataset: {
        source: data,
      },
      series: [
        {
          type: "pie",
          radius: [70, 90],
          center: ["50%", "50%"],
          labelLine: {
            normal: {
              length: 16,
            },
          },
          minAngle: 20, //最小角度
          startAngle: 270, //起始角度
          label: {
            show: true,
            color: "inherit",
            textStyle: {
              fontSize: 12,
            },
            formatter: (params) => {
            //   let a = params.value.name + "-" + params.value.value;
              //调用自定义显示格式
              return `${getEqualNewlineString(params.value.name, 4)} - ${
                params.value.value
              }`;
            },
            //             formatter: (p) => {
            //               return `${p.value.name} - ${p.value.value} \n`;
            //             },
          },
        },
      ],
    };

    return option;
  };
  const getEqualNewlineString = (params, length) => {
    let text = "";
    let count = Math.ceil(params.length / length); // 向上取整数
    // 一行展示length个
    if (count > 1) {
      for (let z = 1; z <= count; z++) {
        text += params.substr((z - 1) * length, length);
        if (z < count) {
          text += "\n";
        }
      }
    } else {
      text += params.substr(0, length);
    }
    return text;
  };
  return (
    <div className="r-box">
      <Title title="文化资源展示" />
      <div className="content" ref={echartsRef}></div>
    </div>
  );
};
