import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import { get } from "../../util/fetch";
import { getMsgCode, getCodeImg, userLogin } from "../../api/auth";
// import Notification from "../../components/Notification";
import { notification } from "antd";

import setting from "../../config/defaultSettings";
import { AesEncrypt } from "../../util/encryption/index";

export default (props) => {
  const [isNote, setIsNote] = useState(false);
  const [time, setTime] = useState(null);
  const timeRef = useRef();

  const [loading, setLoading] = useState(true);
  const [configInfo, setConfigInfo] = useState(null);
  const [userName, setUserName] = useState("");
  const [userError, setUserError] = useState("ok");
  const [password, setPassword] = useState("");
  const [captcha_code, setCaptcha_code] = useState("");
  const [captcha_codeError, setCaptcha_codeError] = useState("ok");

  const [passwordError, setPasswordError] = useState("ok");
  const [codeData, setCodeData] = useState("");
  const [captchaKey, setCaptchaKey] = useState(null);
  const [codeImage, setCodeImage] = useState("");
  const [isMounted, setIsMounted] = useState(true);

  const [codeError, setCodeError] = useState("ok");
  const [showPassword, setShowPassword] = useState("password");
  const [count, setCount] = useState(0); // 倒计时状态

  const loginTypeData = [
    { name: "账号登录", code: "captcha" },
    { name: "短信登录", code: "sms" },
  ];

  const [loginType, setloginType] = useState("captcha"); // 倒计时状态

  // const timer = useRef(null);
  const getInfo = async () => {
    if (!isMounted) {
      // 如果组件已经卸载，则不执行后续操作
      return;
    }
    const res = await get("/web-api/sys-config/info");
    setConfigInfo(res);
    localStorage.setItem("INFO", JSON.stringify(res.data.globalConfig));
    let info = AesEncrypt(JSON.stringify(res.data), setting.aesKey);
    localStorage.setItem("PlatformInfo_" + setting.name, info);
  };
  useEffect(() => {
    if (time && time != 0) {
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else {
      setIsNote(false);
    }
    return () => {
      clearInterval(timeRef.current);
    };
  }, [time]);
  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    getInfo();
    return () => {
      setIsMounted(false);
      clearTimeout(timer);
    };
  }, []);

  const changePasswordStatus = function () {
    if (showPassword == "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };
  const handLogin = function () {
    let ok = true;
    if (userName == "") {
      setUserError("error");
      ok = false;
    } else {
      setUserError("ok");
    }
    console.log(password, captcha_code);
    if (loginType == "captcha") {
      if (password == "") {
        setPasswordError("error");
        ok = false;
      } else {
        setPasswordError("ok");
      }
      if (captcha_code == "") {
        setCaptcha_codeError("error");
        ok = false;
      } else {
        setCaptcha_codeError("ok");
      }
    } else {
      if (codeData == "") {
        setCodeError("error");
        ok = false;
      } else {
        setCodeError("ok");
      }
    }
    console.log(ok);
    if (ok) {
      let info = configInfo.data;
      let params = new FormData();
      // params.append("captcha_type", "image");
      // params.append("password", AesEncrypt(password));

      if (loginType == "sms") {
        params.append("username", userName);
        params.append("captcha_code", codeData);
      } else {
        params.append("username", userName);
        params.append("password", AesEncrypt(password));
        params.append("captcha_code", captcha_code);
        params.append('captcha_type', 'image')
        params.append('captcha_key', captchaKey)
      }

      params.append("grant_type", loginType);
      params.append(
        "client_id",
        info.globalConfig ? info.globalConfig.clientId : info.clientId
      );
      params.append(
        "client_secret",
        info.globalConfig ? info.globalConfig.secretKey : info.secretKey
      );

      userLogin(params)
        .then((res) => {
          console.log(res);
          let data = res.data;
          sessionStorage.setItem(
            setting.name + "_ACCESS_TOKEN",
            data.access_token
          );
          sessionStorage.setItem(
            setting.name + "_userInfo",
            JSON.stringify(data)
          );
          notification.success({
            message: "提示信息",
            description: "登录成功！欢迎回来",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          notification.error({
            message: "提示信息",
            description: err.message,
          });
        });
    }
  };
  const getMessageCode = () => {
    let phoneReg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
    let flag = false;
    if (phoneReg.test(userName)) {
      flag = true;
    } else {
      flag = false;
    }
    if (flag) {
      setTime(60);
      setIsNote(true);
      refresCodeImg();
      setUserError("ok");
    } else {
      setUserError("no");
    }
  };
  const refresCodeImg = () => {
    if (!isMounted) {
      // 如果组件已经卸载，则不执行后续操作
      return;
    }
    getMsgCode({ phone: userName }).then((res) => {
      console.log(res);
    });
  };

  // 切换类型
  const changeLoginType = function (type) {
    setloginType(type);
    console.log(loginType);
  };
  // 获取图片验码
  const getCaptchaImg = function () {
    getCodeImg().then((res) => {
      setCodeImage(res.data.captchaImage);
      setCaptchaKey(res.data.captchaKey);
    });
  };
  useEffect(() => {
    getCaptchaImg();
  }, []);
  return (
    <div className="login-page">
      <div className={`${loading ? "loading" : "hide"}`}>
        <div className={`${loading ? "loading-icon" : "hide"}`}></div>
      </div>
      <div>
        <div className="login-title"></div>
        <div className="login-box">
          {/* <div className="min-title">用户登录</div> */}

          <div className="login-type-box">
            {loginTypeData.map((item, index) => (
              <div
                className={
                  loginType == item.code ? `type-item active` : "type-item"
                }
                key={index}
                onClick={() => changeLoginType(item.code)}
              >
                {item.name}
              </div>
            ))}
          </div>
          {loginType == "sms" ? (
            <div>
              <div className="form-item">
                <span className="icon user"></span>
                <input
                  className="input-item"
                  autoComplete="off"
                  type="text"
                  placeholder="请输入手机号"
                  value={userName}
                  maxLength="26"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
                <div className={userError == "ok" ? "tip hide" : "tip"}>
                  请输入正确的手机号码
                </div>
              </div>

              <div className="form-item">
                <span className="icon code"></span>

                <button
                  disabled={isNote}
                  className="code-img"
                  onClick={getMessageCode}
                >
                  {isNote ? `${time}秒后可再次发送` : "发送验证码"}
                </button>
                <input
                  className="input-item"
                  type="text"
                  maxLength="10"
                  placeholder="请输入验证码"
                  value={codeData}
                  onChange={(e) => {
                    setCodeData(e.target.value);
                  }}
                />
                <div className={codeError == "ok" ? "tip hide" : "tip"}>
                  验证码不能为空
                </div>
              </div>
              <div className="login-btn" onClick={handLogin}>
                登录
              </div>
            </div>
          ) : (
            <div>
              <div className="form-item">
                <span className="icon user"></span>
                <input
                  className="input-item"
                  autoComplete="off"
                  type="text"
                  placeholder="请输入登录账号"
                  value={userName}
                  maxLength="26"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
                <div className={userError == "ok" ? "tip hide" : "tip"}>
                  请输入登录账号
                </div>
              </div>

              <div className="form-item">
                <span className="icon pwd"></span>
                <input
                  className="input-item"
                  autoComplete="off"
                  type="password"
                  placeholder="请输入登录密码"
                  value={password}
                  maxLength="26"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <div className={captcha_codeError == "ok" ? "tip hide" : "tip"}>
                  请输入登录密码
                </div>
              </div>

              <div className="form-item">
                <span className="icon code"></span>

                <div className="code-img" onClick={() => getCaptchaImg()}>
                  <img src={codeImage} />
                </div>
                <input
                  className="input-item"
                  type="text"
                  maxLength="4"
                  placeholder="请输入验证码"
                  value={captcha_code}
                  onChange={(e) => {
                    setCaptcha_code(e.target.value);
                  }}
                />
                <div className={captcha_codeError == "ok" ? "tip hide" : "tip"}>
                  验证码不能为空
                </div>
              </div>
              <div className="login-btn" onClick={handLogin}>
                登录
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
