// import React from "react";
import Header from "../../components/Header";
import ContentMain from "../../components/ContentMain";
import LoginPage from "../../components/LoginPage";
import "./index.scss";
import React, { useEffect, useState } from "react";
import { get } from "../../util/fetch";
import setting from "../../config/defaultSettings";

export default () => {

  const [hasToken, setHasToken] = useState(false);
  
  const handleTokenChange = (status) => {
    setHasToken(status); // 更新 hasToken 状态的函数
  };
  
  useEffect(() => {
    const token = sessionStorage.getItem(setting.name + '_ACCESS_TOKEN');
    if (token) {
      setHasToken(true);
    }
  }, []); // 空依赖数组确保此 e

  
  return hasToken ? (
    <div className="layout">
      <Header />
      <ContentMain />
    </div>
  ) : (
    <div className="loginPage">
      <LoginPage onTokenChange={handleTokenChange} />
    </div>
  );
};
