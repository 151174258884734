import React, { useEffect, useState } from "react";
import Title from "../Title";
import "./index.scss";
import { Swiper, SwiperSlide } from "swiper/react"; // 引入js
import "swiper/swiper.min.css"; // 引入样式
import SwiperCore, { Autoplay } from "swiper";
import { queryWeb_apiScreen_showNewDiscoveryStaticNoLogin } from "../../api/zypc";
SwiperCore.use([Autoplay]);
export default (props) => {
  const { region } = props;
  const [info, setInfo] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [newDiscover, setNewDiscover] = useState(0);
  useEffect(() => {
    getData();
  }, [region]);
  const getData = async () => {
      queryWeb_apiScreen_showNewDiscoveryStaticNoLogin().then((res) => {
        setInfo(res.data);
        setNewDiscover(res?.newDiscovery);
        setTotal(res?.total);
      })
  };
  const btnMouseEnterEvent = (e) => {
    let el = document.getElementsByClassName("resource_detail")[0];
    if (e.currentTarget.getAttribute("data-name")) {
      console.log(el);
      el.style.display = "block";
      setDataIndex(e.currentTarget.getAttribute("data-name"));
    }
  };
  const btnMouseLeaveEvent = (e) => {
    let el = document.getElementsByClassName("resource_detail")[0];
    if (el) {
      el.style.display = "none";
    }
  };
  return (
    <div className="rc-box">
      <Title title="旅游资源新发现分类统计" />
      <div className="content">
        <div className="top-legend">
          <div className="item" style={{ marginRight: 50 }}>
            <span className="icon"></span>
            <span className="desc">资源总数</span>
            <span className="total">{total}</span>
          </div>
          <div className="item">
            <span className="icon newIcon"></span>
            <span className="desc">新发现</span>
            <span className="total">{newDiscover}</span>
          </div>
        </div>
        <div className="barWrapper">
          <Swiper
            autoplay={{
              delay: 2500,
              pauseOnMouseEnter: false,
            }}
            loop={true}
            direction="vertical"
            observer
            observeParents
            observeSlideChildren
            slidesPerView={3}
            loopedSlides={3}
            style={{ width: "400px", height: "150px" }}
          >
            {info &&
              info.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="item"
                      data-name={index}
                      onMouseOver={btnMouseEnterEvent}
                      onMouseLeave={btnMouseLeaveEvent}
                    >
                      <p>{item.name}</p>
                      <div className="barBox">
                        <span className="totalresource">{item.total}</span>
                        <span className="bar">
                          <span
                            className="innerbar"
                            style={{
                              width: `${parseInt(
                                (1 - item.newDiscovery / item.total) * 100
                              )}%`,
                            }}
                          ></span>
                        </span>
                        <span className="new">{item.newDiscovery}</span>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        <div className="resource_detail">
          <div className="resource_detail_box">
            <div>{info[dataIndex]?.name}</div>
          </div>
          <div className="resource_detail_data">
            <div>新发现数：</div>
            <div className="total_count1">{info[dataIndex]?.newDiscovery}</div>
          </div>
          <div className="resource_detail_data_center">
            <div className="total_count">资源总数：</div>
            <div className="total_count1">{info[dataIndex]?.total}</div>
          </div>
          <div className="resource_detail_data">
            <div>新发现占比：</div>
            <div className="total_count1">{info[dataIndex]?.per}</div>
          </div>
          {/* {dataObj.map((item) => (
              <div className="item" key={item.grade}>
                <div className="barBox">
                  <div>{item.gradeName} ：</div>
                  <div className="count">{item.count}</div>
                  <div>{item.per}</div>
                </div>
              </div>
            ))} */}
        </div>
        {/* <div className="barWrapper">
          {info.map((item) => (
            <div className="item" key={item.name}>
              <p>{item.name}</p>
              <div className="barBox">
                <span className="totalresource">{item.total}</span>
                <span className="bar">
                  <span
                    className="innerbar"
                    style={{
                      width: `${parseInt(
                        (1 - item.newDiscovery / item.total) * 100
                      )}%`,
                    }}
                  ></span>
                </span>
                <span className="new">{item.newDiscovery}</span>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};
